<template>
  <section class="hero is-fullheight">
    <Navbrand />
    <div class="container">
      <div class="box has-text-centered">
        <h1 class="title title-font" v-text="'Airdrop Log In'" />
        <p class="subtitle is-size-6 is-spaced">
          {{ $t('AIRDROP_LOGIN') }}
          <router-link to="/airdrop" v-text="airdropReward" />
        </p>
        <div class="has-text-left is-size-5">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <p
                class="is-size-5"
              > {{ $t('AIRDROP_LOGIN_DESC') }}</p>
            </div>
          </div>
          <div class="columns is-vcentered is-mobile">
            <div class="column pl-0 pt-40">
              <LoginAirdropEmail />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Navbrand from "@/components/partials/Navbrand.vue";
import LoginAirdropEmail from "@/components/partials/LoginAirdropEmail";

export default {
	name: "AirdropLogin",
	components: {
		Navbrand,
		LoginAirdropEmail
	},
	data() {
		return {
			airdropReward: this.$t('GET_100_MPH')
		};
	},
	head() {
		return {
			title: { inner: this.$t('meta.AIRDROP_LOGIN_TITLE') },
			meta: [{ hid: "robots", name: "robots", content: "noindex" }]
		};
	}
	// async created() {
	//   utils.getAirdropRewards(this.airdropReward).then(result => {
	//     this.airdropReward = result;
	//   });
	// },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
	background-color: #fafcfd;
}
.box {
	margin-top: 60px;
	width: 600px;
	padding: 1.5rem 3rem;
	/* box-shadow: 0 5px 12px 0 rgba(182, 191, 210, 0.2); */
	box-shadow: 0 10px 20px 0 rgba(182, 191, 210, 0.4);
	border-radius: 13px;
	border: 3px solid rgba(255, 255, 255, 0.6);
}
.social-links a {
	padding-left: 0.75rem;
	color: #666;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.social-links a:hover {
	color: #000;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.top-padding {
	margin-top: 2rem;
}
.kyc-hr {
	margin-top: 2.5rem;
}
.b-checkbox {
	padding-top: 5px;
}
.complete-hr {
	margin-top: 1rem;
}
.social-buttons {
	margin-top: initial;
}
.custom-loader {
	margin: 15px auto;
	width: 30px;
	height: 30px;
	border-width: 4px;
	border-color: transparent transparent #00c386 #00c386;
}
.cta-btn.button {
	border-radius: 5px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 1.25rem;
	height: 3.25rem;
	padding: 0 5rem;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button:hover {
	background-color: #00c386;
	border-color: #00c386;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-primary {
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-primary:hover {
	background-color: #00d492;
	border-color: #00d492;
	box-shadow: 0px 2px 4px rgba(0, 161, 111, 0.1),
		0px 4px 10px rgba(0, 161, 111, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-twitter {
	padding: 0 0.25rem;
	width: 9.5rem;
	color: #fff;
	border-color: #1da1f2;
	background-color: #1da1f2;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-twitter:hover {
	background-color: #4db5f5;
	border-color: #4db5f5;
	box-shadow: 0px 2px 4px rgba(77, 181, 245, 0.1),
		0px 4px 10px rgba(77, 181, 245, 0.1);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-telegram {
	padding: 0 0.25rem;
	width: 9.5rem;
	color: #fff;
	border-color: #0088cc;
	background-color: #0088cc;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-telegram:hover {
	background-color: #00aaff;
	border-color: #00aaff;
	box-shadow: 0px 2px 4px rgba(0, 170, 255, 0.1),
		0px 4px 10px rgba(0, 170, 255, 0.1);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-whatsapp {
	padding: 0 0.25rem;
	width: 9.5rem;
	color: #fff;
	border-color: #25d366;
	background-color: #25d366;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-whatsapp:hover {
	background-color: #4be083;
	border-color: #4be083;
	box-shadow: 0px 2px 4px rgba(75, 224, 131, 0.1),
		0px 4px 10px rgba(75, 224, 131, 0.1);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.input-button {
	border-radius: 5px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border: none;
	background: #00c386;
	font-weight: 700;
	font-size: 1.25rem;
	padding: 0 1.7rem;
	height: 3.25rem;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	outline: none;
}
.input-button:hover {
	cursor: pointer;
	outline: none;
	background: #00d492;
	color: #fff;
	box-shadow: 0px 1px 2px rgba(0, 161, 111, 0.2),
		0px 6px 12px rgba(0, 161, 111, 0.2);
	transform: translateY(0);
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.input-button:focus {
	color: #fff;
	outline: none !important;
	border: none !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
	background: #00c386;
}
.input-button:hover:active {
	background: #00a16f;
	transition-delay: 0s;
	transition-duration: 0.1s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.5, 0.46, 0.45, 0.94);
}
.input {
	width: 335px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	height: 3em;
	padding-left: 15px;
	padding-right: 30px;
	font-size: 18px !important;
	font-family: "Roboto", sans-serif;
}
.input:focus,
.input:hover {
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border-color: #00c386;
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ml-10-tablet {
	margin-left: 12px;
}
.is-hidden-400 {
	display: none;
}
@media only screen and (max-width: 768px) {
	.box {
		width: 90%;
		margin: 0 auto;
		margin-top: 20px;
	}
	.input-button,
	.cta-btn,
	.input {
		width: 100%;
	}
	.ml-10-tablet {
		margin-left: 0px;
	}
	.mt-15-mobile {
		margin-top: 15px;
	}
	.cta-btn.button.is-twitter,
	.cta-btn.button.is-telegram,
	.cta-btn.button.is-whatsapp {
		width: 100%;
	}
}
@media only screen and (max-width: 400px) {
	.is-hidden-400 {
		display: inherit;
	}
}
</style>
