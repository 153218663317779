<template>
  <div>
    <!-- recaptcha -->
    <div v-show="showRecaptchaMessage" v-once>
      <vue-recaptcha ref="recaptcha" 
                     size="invisible" 
                     :sitekey="recaptchaSiteKey" 
                     :loadRecaptchaScript="loadRecaptchaScript"
                     @verify="onCaptchaVerified" 
                     @error="onCaptchaError"
                     @expired="onCaptchaExpired" 
      />
    </div>

    <!-- email input -->
    <div class="field is-inline-flex-tablet columns">
      <div class="control ctrl-input column is-7-tablet is-8-desktop">
        <b-field :class="{ 'bad-input': emailValidation === false }" :message="emailMessage">
          <input v-model="email" 
                 class="input is-large" 
                 :class="{ 'input-bad': badFeedbackVisual }" 
                 type="email" 
                 name="email" 
                 autocomplete 
                 placeholder="email@address.com" 
                 @focus="showRecaptchaMessage = true" 
                 @blur="showRecaptchaMessage = false" 
                 @keyup.enter="subscribe"
                 @keydown="emailValidation = true; emailMessage = ''"
          >
        </b-field>
      </div>

      <!-- submit button -->
      <div class="control ctrl-btn column is-5-tablet is-4-desktop">
        <button type="submit"
                class="button is-large input-button"
                :class="{ 'is-loading is-primary': loading }"
                @click="subscribe"
        >
          <span class="is-size-6 is-fs-18">
            {{ $t('LOGIN_TO_AIRDROP') }}
          </span>
        </button>
      </div>
    </div>

    <!-- submit feedback -->
    <div class="is-fs-14" style="marginTop:-3px">
      <p v-show="invalidEmail && badFeedbackMessage">{{$t('valid_email')}}</p>
      <p v-show="hasError && badFeedbackMessage">{{$t('something_went_wrong')}}</p>
      <p v-show="showRecaptchaMessage && !badFeedbackMessage" v-html="$t('recaptcha_usage')" />
    </div>
  </div>
</template>

<script>

import validator from "email-validator";
import VueRecaptcha from "vue-recaptcha";
import emailService from "../../services/Email";
import {  mapActions } from 'vuex';

export default {
  name: "LoginAirdropEmail",
  components: {
    "vue-recaptcha": VueRecaptcha
  },
  data() {
    return {
      hasError: false,
      showRecaptchaMessage: false,
      invalidEmail: false,
      badFeedbackMessage: false,
      badFeedbackVisual: false,
      emailValidation: true,
      emailMessage: '',
      loading: false,
      email: "",
      submittedEmail: "",
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA,
      recaptchaToken: "",
      loadRecaptchaScript: true,
    };
  },
  watch: {
    email(newValue) {
      // Validate all email input
      if (newValue !== "") {
        this.invalidEmail = !validator.validate(newValue);
      }
      // Reset input color once start entering new email
      if (newValue !== "" && this.badFeedbackVisual) {
        this.badFeedbackVisual = false;
      }
    }
  },
  methods: {
    subscribe() {
      if (!this.invalidEmail) {
        emailService.startLogin(this);
        this.$refs.recaptcha.execute();
      } else {
        this.badFeedbackMessage = true;
      }
    },
    resetSate() {
      this.loading = false;
      this.hasError = false;
      this.badFeedbackMessage = false;
      this.badFeedbackVisual = false;
    },
    onCaptchaError() {
      this.loading = false;
      this.emailValidation = false;
      if(!window.__PRERENDER_INJECTED) {
        this.emailMessage = this.$t('ERROR_LOADING_RECAPTCHA');
      }
    },
    onCaptchaVerified(response) {
      this.recaptchaToken = response;
      this.$refs.recaptcha.reset();
      emailService.getAirdropLogin(this);
    },
    onCaptchaExpired() {
      this.loading = false;
      this.hasError = true;
      this.$refs.recaptcha.reset();
      this.emailValidation = false;
      this.emailMessage = this.$t('ERROR_LOADING_RECAPTCHA_TRY');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.space-reg {
  margin-bottom: 21px;
}
a {
  color: #228cdb;
}
a:hover {
  color: inherit;
}
.min-padding {
  padding-bottom: 10px;
}
.is-fs-18 {
  font-size: 18px !important;
}

.input-button:focus {
  color: #fff;
  outline: none !important;
  border: none !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
  background: #00c386;
}

.input-button:hover:active {
  background: #00a16f;
  transition-delay: 0s;
  transition-duration: 0.1s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.5, 0.46, 0.45, 0.94);
}

.input {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 3em;
  /* padding-left: 15px; */
  /* padding-right: 30px; */
  /* margin-right: 50px; */
  font-size: 18px !important;
  font-family: "Roboto", sans-serif;
}

.input:focus,
.input:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #00c386;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.input-bad:focus,
.input-bad:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #f15a5a;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.input-button {
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: none;
  background: #00c386;
  font-family: "Roboto", sans-serif;
  font-weight: 600 !important;
  color: #fff;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  outline: none;
  width: 100%;
}

.input-button:hover {
  cursor: pointer;
  outline: none;
  background: #00d492;
  color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 161, 111, 0.2),
    0px 6px 12px rgba(0, 161, 111, 0.2);
  transform: translateY(0);
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.field {
  width: 100%;
}
.ctrl-btn {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 768px) {
  .field {
    width: initial;
  }
  .ctrl-input {
    padding-bottom: 0;
  }
  .ctrl-btn {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.bad-input {
    color: #fc6404 !important;
}

.bad-input input.input {
    border-color: #fc6404 !important;
}

</style>